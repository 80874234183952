import React from 'react'
import { graphql } from 'gatsby'

import PATHS from 'paths'
import PartnerLandingPageLayout from 'layouts/PartnerLandingPageLayout'
import { PRODUCTS } from 'lib/products/constants'
import JSONLD from 'components/JSONLD'
import CREMATION_JSONLD from 'lib/json-ld/cremation'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import Content from 'views/cremation/ppc/SimpleCoreLocal/Content'

interface PartnerLandingPageProps {
  data: {
    page: {
      partner: {
        bannerBackgroundColor: string
        bannerLogoAlignment: 'left' | 'right'
        bannerTextColor: string
        logo: { file: { url: string }; title: string }
        utmChannel: string
        utmSource: string
        partnerId: string
      }
      bannerText: { bannerText: string }
      heroCtaText: string | null
      heroSubtitle: { heroSubtitle: string } | null
      heroTitle: string | null
    }
  }
}

const PartnerLandingPage = ({
  data: {
    page: { partner, heroTitle, heroSubtitle, heroCtaText, bannerText },
  },
}: PartnerLandingPageProps): React.ReactElement => {
  const { utm_campaign: utmCampaign } = getQueryParameters()

  return (
    <>
      <JSONLD data={[CREMATION_JSONLD]} />
      <PartnerLandingPageLayout
        title="Arrange a simple, personal direct cremation"
        description="Arrange a simple, personal direct cremation with the UK’s trusted funeral specialists"
        product={PRODUCTS.FUNERAL}
        canonicalPath={PATHS.CREMATION.PPC.FUNERAL_SIMPLE_CORE_LOCAL}
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
        utmCampaign={utmCampaign as string}
        partner={partner}
        bannerText={bannerText?.bannerText}
      >
        <Content
          ctaText={heroCtaText || undefined}
          subtitle={heroSubtitle?.heroSubtitle}
          title={heroTitle || undefined}
        />
      </PartnerLandingPageLayout>
    </>
  )
}

export const query = graphql`
  query PartnerLandingPage($slug: String!) {
    page: contentfulPartnerLandingPageCremation(slug: { eq: $slug }) {
      partner {
        bannerTextColor
        bannerBackgroundColor
        bannerLogoAlignment
        logo {
          file {
            url
          }
          title
        }
        utmChannel
        utmSource
        partnerId
      }
      bannerText {
        bannerText
      }
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      heroCtaText
    }
  }
`

export default PartnerLandingPage
